function SelectOption(props){

    function clickFunc(){
        props.clickFunc(!props.state,props.value)
    }

    return  (

        <div className="selectOptionContainer" onClick={ clickFunc } > 
        
        <div style={{background : props.state ?  "#47C095" : "#34206C" , color : "white", width : "50px", height : "50px", borderRadius : "50px", textAlign : "center", lineHeight : "45px"}}  >
            {props.label} 
        </div>
        
        </div>
    )

}

export default SelectOption