import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams
} from "react-router-dom";
import useFetch from './hooks/hooks.js';

//pages
import LinesList from './pages/LinesList.js'
import AddLine from './pages/AddLine.js'
import EditLine from './pages/EditLine.js';
import {Line} from  './pages/Pages.js'
import { useCookies } from 'react-cookie'
import EditBoard from "./pages/EditBoard.js";
import EditViews from "./pages/EditViews.js";

//cotext
import  { useContext,useState }   from 'react';
import UserContext                from "./Contexts/UserContext.js"
import { BoardContext }           from "./Contexts/BoardContext.js"
import { LineContext }            from "./Contexts/LineContext.js"


function Routes(){
  const cookies = useCookies()[0];
  
  var userContext = useContext(UserContext)
  

  let redirectToLastBoard = ()=>{

    var id = cookies.lastBoardId ? cookies.lastBoardId : userContext.user.boardList[0]


    return (
      <Redirect to= {`/board/${id}`}/>  
    )
  }

  return (  
    <Router>

      <Switch>
        
        <Route exact path="/login" > <Redirect to="/"/> </Route>
        <Route exact path="/"> {redirectToLastBoard()} </Route>

        {/* BOARD CONTEXT */}
        <Route path={ `/board/:boardId` }>  
        
          
            <Switch>
                
                <Route exact path={ `/board/:boardId` }> 
                  <BoardProvider>    
                    <LinesList/> 
                  </BoardProvider>  
                </Route> 

                <Route exact path={ `/board/:boardId/edit` }> 
                  <BoardProvider>    
                    <EditBoard/> 
                  </BoardProvider>  
                </Route> 

                <Route exact path={ `/board/:boardId/views` }> 
                  <BoardProvider>    
                    <EditViews  edit={false} />
                  </BoardProvider>  
                </Route> 

                <Route exact path={ `/board/:boardId/views/:viewId` }> 
                  <BoardProvider>    
                    <EditViews edit={false}/>
                  </BoardProvider>  
                </Route> 

                <Route exact path={ `/board/:boardId/views/:viewId/edit` }> 
                  <BoardProvider>    
                    <EditViews edit={true} />
                  </BoardProvider>  
                </Route> 

            </Switch>    


          <BoardProvider>  
            <Switch>
              
              <Route exact path={ `/board/:boardId/addLine` }> <AddLine views={userContext.user.currentviews} /> </Route> 
              
              {/* LINE CONTEXT */}
              <Route path="/board/:boardId/line/:lineId">    
                <LineProvider>
                  
                  <Switch>
                    <Route exact path={ `/board/:boardId/line/:lineId`     }>   <Line/>       </Route>
                    <Route exact path={ `/board/:boardId/line/:lineId/edit`}>   <EditLine/>   </Route> 
                  </Switch>
                
                </LineProvider>
              </Route>


              </Switch>    
          </BoardProvider>


        </Route> 

      </Switch>
          
    </Router>
    )
}

function LineProvider(props){

  const {lineId,boardId} = useParams();
  let [line, setLine] = useState(null);
  let value = {line, setLine}

  const {onFetch} = useFetch({ 
    
    url : `/board/${boardId}/line/${lineId}`,
    fetchOnMount : false,
    method : "GET",
    sucess : (data)=>{  setLine(data)  }

  })

  if(line === null){

    setLine({loading : true})
    onFetch()
  }

  return (
    <LineContext.Provider value={ value }>
      {props.children}
    </LineContext.Provider>
  )

}

function BoardProvider(props){

  const {boardId} = useParams();

  let [board, setBoard] = useState(null);
  let value = {board, setBoard}

  const {onFetch} = useFetch({ 
    url : `/board/${boardId}/lines`,
    fetchOnMount : false,
    sucess : (data)=>{ 
      setBoard(data)  
    }
  })
  
  
  if(board && board.board){ 
    console.log(board,parseInt(boardId));
    console.log(parseInt(board.board.incrementId));
  } 

  if(board === null ){
    setBoard(true)
    console.log("1111111111");
    onFetch()
  }else if (board.board && parseInt(boardId) !== parseInt(board.board.incrementId)){
    setBoard(true)
    console.log("22222222");
    onFetch()
  }

  return (

    <BoardContext.Provider value={ value }>
      {props.children}
    </BoardContext.Provider>

  )


}

export default Routes;
