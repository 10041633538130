//REact
import {React, useContext } from 'react';
import {useParams,Link,useHistory} from "react-router-dom";

//components
import FloatingBtn from "../Components/floatingBtn.js"
import Views from "../Components/views.js"
import LineHeader from '../Components/lineHeader.js';

//context
import { LineContext } from "../Contexts/LineContext.js"


//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------

function Line(){

  let {line} = useContext(LineContext)

  
  if(line && line.loading !== true ){ 

    return (
      <LineContent {...line.line}/>    
    )
     
  }

  return (  <div className="lineWrapper" > <LineHeader back={()=>{}}/> </div> ) 
};


const LineContent = (props) => {

  const history = useHistory()
  let { boardId,lineId } = useParams();

  let content = ()=>{

    return (
      <Link to={ `${lineId}/edit` }>Edit</Link>
    )
    
  }

  function back(){
    history.push(`/board/${boardId}`)
  }

  return (

    <div className="lineWrapper">   
      
      <LineHeader {...props} back={back}/>
      <FloatingBtn content={content()} clickFunc={()=>{}} visible={true} color={"#34206C"} /> 
      <Views selectedHolds={props.views} edit={false}/>
    
    </div>
  )
};


export default Line 