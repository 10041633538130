import {Form} from "../Components/form.js"
import { InputList,InputHolds } from "../Components/FormInputs/allFormInputs.js"
import settings from "../settings.js"

const set = new settings()
const gradeList = set.getCurrentGradeList()
const angleList = set.getAngleList()

function FilterForm(props){

  function back(){ 
    setTimeout(()=>{ props.setHeaderVisible(true) },400)
    props.setOpen(false);
    props.setClosing(true);
  }
  
  function formCallBack(_formData){
    
    props.onFilterUpdate({
      angle : _formData.angle,
      grade : _formData.grade,
      rating : _formData.rating,
      holds : _formData.holds
    })

    back()
  }

  if(props.formVisible){

    return (
      
        <div id="formWrapper" style={{ position : "absolute", width : "100%", height : "100%", zIndex : 999 }}>
          
          <Form 
            closeForm = {props.isOpen}
            floatingContent={"OK"}
            floatingType={"white"}
            actionName={"Clear"}
            formCallBack={formCallBack}
            back={back}
            backOnCallback={false}
            headerTitle={"Filters"}
            isVisible = {props.formVisible}
            backBtn = {true}
          >
            <InputList  title={"Angle"}   selectedValue={props.filtersData.angle}       options={angleList}   selectLimit={false} id={"angle"}/>
            <InputList  title={"Grade"}   selectedValue={props.filtersData.grade}       options={gradeList}   selectLimit={false} id={"grade"}/> 
            <InputHolds title={"Holds"}   selectedValue={props.filtersData.holds}       options={props.views} selectLimit={false} id={"holds"}/>
          
          </Form>
        </div>
    
    )

  }else if(true){
    return null
  }

}

export default FilterForm