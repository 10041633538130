import  { useRef, useState, useContext } from 'react';
import FilterForm from './FilterForm.js';
import { BoardContext } from "../Contexts/BoardContext.js"


function Header(props){

  // const setCookie = useCookies(['lastBoardId'])[1];
  let updateFilters = (_filtersData) => {
    
    var newfilter = {...props.filtersData}
    newfilter = Object.assign(newfilter,_filtersData)
    props.setFilterData(newfilter)
    
  }

  var {board} = useContext(BoardContext)

  const views = board.board ? board.board.views : {}
  
  const [isOpen,setOpen]= useState(false)
  const [formVisible,setVisible] = useState(false)
  const [headerVisible,setHeaderVisible] = useState(true)
  const [isClosing,setClosing] = useState(false)
  
  let height = isOpen ? "100%" :  "130px"
  
  function transitionEnd(_e){
    
    if(_e.propertyName === "height" && isClosing){ 
      setVisible(false);  
      setClosing(false); 
    }
  }

  return (
    
    <>
      {/* -------------   Header  -------------- */}
      <div id="header" style={{ height :  height, flexShrink: 0 }}>
          
          <div className="filterHeader" style={{ height :  height  }} onTransitionEnd={ (_e)=>{transitionEnd(_e)} }>

            {/* ---------- BOARD SELECTOR */}
            <div className="boardSelector" style={{height: "70px", transition : headerVisible ? "opacity 200ms" : "opacity 100ms" , opacity : headerVisible ? 1 : 0 }}>

              <div className="hamburguerMenu" onClick={()=>{
                props.setSideMenu(true)
              }} > 
                
                <div style={{ transform : "scale(0.7)" }}>
                  <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="15" height="4" rx="2" fill="#FAFAFA"/><rect y="19" width="30" height="4" rx="2" fill="#FAFAFA"/><rect y="9" width="23" height="4" rx="2" fill="#FAFAFA"/></svg>
                </div>
                
                {/* FIX ME  - crair modulo de loagind  */}
                <div className="boardName"> 
                  {  (()=>{ if(board.board){ return board.board.name }else{ return "" }})() }  
                </div>
              
              </div>
            </div>
            
            {/* ---------- FILTER */}
            <Filter
              headerVisible = {headerVisible}
              setHeaderVisible = {setHeaderVisible}
              setVisible = {setVisible} 
              formVisible = {formVisible}
              setOpen = {setOpen}
              isOpen = {isOpen}
              setClosing = {setClosing}
              onFilterUpdate = {updateFilters}
              views = {views}
              filtersData = {props.filtersData  }
            />


          </div>
        </div>
    </>
  )
}

function Filter({headerVisible, setVisible, setOpen, setHeaderVisible, onFilterUpdate,isOpen,views,setClosing,formVisible,filtersData}){

  const nameFilter = (_e)=>{ onFilterUpdate({ name : [_e.target.value]}) }

  return (
    <>
      {/* ---------------  Filter Form  ------------ */}
      <FilterForm 
        setOpen={setOpen} 
        isOpen={isOpen}
        formVisible={formVisible}
        setClosing={setClosing}
        setHeaderVisible={setHeaderVisible}
        filtersData={filtersData}
        onFilterUpdate={onFilterUpdate}
        views={views}
      />

      <div className="searchwrapper" style={{height: "60px", transition : headerVisible ? "opacity 200ms" : "opacity 100ms" , opacity : headerVisible ? 1 : 0 }}>


        {/* ---------- BUSCA */}
        <SearchBox nameFilter = {nameFilter}  />
        
        {/* ---------- Filter */}
        <div className="filterName" onClick={()=>{  setVisible(true); setOpen(true); setHeaderVisible(false) }} style={{ marginRight : "20px"}}>
          Filtrar
          {/* <div className="filtericon" > 
            <div className="inconCounter" style={{opacity :  filterCount===0? 0 : 1}} > { filterCount } </div>
            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0 1.18752C0 0.872567 0.127933 0.570518 0.355656 0.347815C0.583379 0.125113 0.892237 0 1.21429 0H15.7857C16.1078 0 16.4166 0.125113 16.6443 0.347815C16.8721 0.570518 17 0.872567 17 1.18752V4.75006C16.9999 5.06499 16.872 5.36699 16.6442 5.58964L10.9286 11.1793V15.4377C10.9285 15.7526 10.8005 16.0546 10.5728 16.2773L8.14421 18.6523C7.97439 18.8183 7.75805 18.9314 7.52254 18.9772C7.28702 19.023 7.04291 18.9995 6.82106 18.9096C6.59921 18.8198 6.40959 18.6676 6.27616 18.4724C6.14272 18.2771 6.07148 18.0476 6.07143 17.8127V11.1793L0.355786 5.58964C0.128047 5.36699 6.8774e-05 5.06499 0 4.75006V1.18752Z" fill="#34206C"/></svg>
          </div> */}
        </div>  

      </div>
    </>
  )


}


function SearchBox(props){

  const [isOpen,setOpen] = useState(false)
  const inputEl = useRef(null);

  var width = "100%"

  return (
    
    <div  style={{ width : isOpen ? width : "15px"  }} className="searchBox" >
      
      <input ref={inputEl} style={{ width : isOpen ? width : "0px", opacity : isOpen ? 1 : 0  }} className="searchInput" onChange={props.nameFilter}/>
      
      <div className="searchIcon" style={{ opacity : isOpen ? 0.4 : 1, transform : isOpen ? "scale(0.7)" : "scale(0.9)"  }} onClick={ ()=>{

        setOpen(!isOpen)
        
        if(!isOpen){
          
          inputEl.current.focus()
        
        }else{
          inputEl.current.value = ""
          props.nameFilter({ target : { value : ""} })

        }
        
      }}
      > 
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.91398 1C11.0129 1 13.0258 1.83382 14.51 3.31802C15.9942 4.80222 16.828 6.81524 16.828 8.91422C16.828 10.6487 16.2698 12.2524 15.3243 13.5569L20.6275 18.8603C20.8568 19.0863 20.99 19.392 20.9995 19.7138C21.0089 20.0356 20.8938 20.3486 20.6781 20.5876C20.4624 20.8266 20.1628 20.9731 19.8418 20.9967C19.5207 21.0202 19.2029 20.9189 18.9547 20.7139L18.8598 20.6281L13.5566 15.3247C12.2072 16.3047 10.5816 16.8312 8.91398 16.8284C6.81506 16.8284 4.80211 15.9946 3.31795 14.5104C1.83379 13.0262 1 11.0132 1 8.91422C1 6.81524 1.83379 4.80222 3.31795 3.31802C4.80211 1.83382 6.81506 1 8.91398 1V1ZM8.91398 3.49923C7.47787 3.49923 6.10059 4.06973 5.08512 5.08524C4.06964 6.10075 3.49915 7.47807 3.49915 8.91422C3.49915 10.3504 4.06964 11.7277 5.08512 12.7432C6.10059 13.7587 7.47787 14.3292 8.91398 14.3292C10.3501 14.3292 11.7274 13.7587 12.7428 12.7432C13.7583 11.7277 14.3288 10.3504 14.3288 8.91422C14.3288 7.47807 13.7583 6.10075 12.7428 5.08524C11.7274 4.06973 10.3501 3.49923 8.91398 3.49923V3.49923Z" fill="#ffffff" stroke="#ffffff" strokeWidth="0.4"/></svg> 
      </div>
    </div>
  )

}


export default Header