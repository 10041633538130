import  { useEffect,useRef, useState } from 'react';
import FormInput from './FormInput.js';

function InputText (props){
	
  
	let [selectedValue,setSelectedValue] = useState(props.selectedValue)
	let [temporaryValue,settemporaryValue] = useState(selectedValue)
  const inputEl = useRef(null);

	const selectNewValue = () =>{
		setSelectedValue(temporaryValue)
		props.dataChangeCallBack(temporaryValue)
	}
  
	const closePopUp = ()=>{ settemporaryValue(selectedValue) }
	const openPop = ()=>{ }
  
	
	const TextInput = () => {

		useEffect(()=>{
			inputEl.current.innerHTML = selectedValue
			inputEl.current.focus()
		})

		return (

			<div className={"textInputValue"} contentEditable={"True"} ref={inputEl} onBlur={
				(_e)=>{ 
					settemporaryValue( _e.target.innerHTML.replaceAll("<div>"," ").replaceAll("</div>"," ").replaceAll("<br>"," ") ); 
			}
		}/> 
				
		)

	}
  

	const getPopUpContent = () => {

		let popUpContent = (

			<div className={"textInputContaier"} onClick={()=>{ inputEl.current.focus() }}>
				<TextInput/>
			</div>

		)
	
		return popUpContent

	}

	const getSelectedValueContent = ()=>{

		let selectedValueContent;
		selectedValueContent = selectedValue
		return selectedValueContent

	}

	return (
		<>
			<FormInput {...props}
				fullScrennPopUp={false}
				setSelectedValue={setSelectedValue}
				selectedValue={selectedValue}
				okFunc = { selectNewValue }
				closePopUp= { closePopUp }
				openPop = { openPop }
				popUpContent = {getPopUpContent()}
			>
		
				{getSelectedValueContent()}

			</FormInput>
		</>
	)
  
}

export default InputText

