import  { cloneElement, useState, useEffect } from 'react';
import FloatingBtn from "../Components/floatingBtn.js" 
import BackBtn from "../Components/BackBtn.js" 


function Form(props){

  //--------setup fadeIn
  const [fadeIn,setFadeIn] = useState(props.closeForm)

  useEffect(()=>{ 
    setTimeout(()=>{ 
      setFadeIn(true) 
    },10) 
  },[] )


  if(fadeIn && !props.closeForm){
    setFadeIn(false)
  
  }

  //-------setup children and form data
  const initialFormData = {}
  
  const renderChildren = () => {

    if(!props.children){
      return null
    }
    
    return props.children.map((value,index)=>{

      
      //FIX ME : quando for holds alterar de array para object
      initialFormData[value.props.id] = value.props.selectedValue

      return cloneElement(value,{
        visible : fadeIn, 
        key : index,
        delay : (index * 50),
        delayOut : 75 - (index * 25),
        
        dataChangeCallBack : (_data) => {

          currentFormData[value.props.id] = _data
          setFormData(currentFormData)

        }

      })
      
    })

    
  }


  const newChildren = renderChildren()
  const [currentFormData,setFormData] = useState(initialFormData)

  //-------- function declaration
  function back(){
    setFadeIn(false)
    props.back();
  }


  const returnFunc = ()=>{
    props.formCallBack( currentFormData )
  }
  

  let backBtn = () => {

    if(props.backBtn){
      
      return ( <BackBtn back={back} fadeIn={fadeIn} /> )

    }else{

      return null

    }

  }
  
  //-------- Render
  return (
    <>
      
      <div id="floatingWrapper" style={ { height : "100%", top : 0, width : "100%", position : "fixed" }} >
          <FloatingBtn  content={props.btnContent} color={props.btnColor} visible={fadeIn} clickFunc={returnFunc} />
        </div>      

      <div className="formWrapper">
        
        <div className="formHeader" style={{ opacity : fadeIn ? "1" : "0" }} >
          {backBtn()}
          <div className="fromHeaderContent" >{props.headerTitle}</div>
        </div>
        
        <div className="formOptionWrapper">
          {newChildren}
          <div style={{height : "120px"}}> </div>
        </div>
      
      </div>
    </>
  )
}

export { 
  Form
}