import  { createContext } from 'react';

const UserContext = createContext( 
  {
    user : { 
      userId : undefined
    },
    setUser : ()=>{}
  }
);

export default UserContext