
function BackBtn(props){

  return (
    <div className="backBtn" style={{ transform : props.fadeIn ? "scale(1)" : "scale(0)" }} onClick={ () => { props.back() }}> 
        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 12L2 7L7 2" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/></svg>
    </div>
  )

}

export default BackBtn
