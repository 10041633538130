import {Form} from "../Components/form.js"
import settings from "../settings.js"
import { useParams,useHistory } from "react-router-dom";
import { InputList,InputHolds,InputText } from "../Components/FormInputs/allFormInputs.js"
import useFetch from '../hooks/hooks.js';

import UserContext from "../Contexts/UserContext.js"
import  { useContext } from 'react';

const set = new settings()
const gradeList = set.getCurrentGradeList()
const angleList = set.getAngleList() 

function AddLine(props){

  var userContext = useContext(UserContext)
  let params = useParams()
  let views = userContext.user.currentViews.board.views
  let selectedValue = { 0 : { holds : {}}}
  const history = useHistory()
  let {content,onFetch} = useFetch({
          
    url : `/${params.boardId}/addLine`,
    fetchOnMount: false,
    method : "POST",
    error : ()=>{},
    sucess : ()=>{}
  
  })
  const ratings = {
    "*" : 1,
    "**" : 2,
    "***" : 3 ,
    "****" : 4,
    "*****" : 5,
  }

  const addlineMapper = (_value) => {

    let outPut = {

      "name": _value.name,
      "angle": _value.angle[0],
      "grade": _value.grade[0],
      "rating": _value.rating[0],
      "date": Date().now,
      "comment" : _value.comment,
      "views" : _value.holds

    }
    return outPut

  }


  if(content){ 
  
    history.push(`/board/${params.boardId}/line/${content.id}`) 
    return null
  }

  return (
    
    <div className = "AddlineWrapper">
      
      <Form
        floatingContent={"OK"}
        floatingType={"white"}
        actionName={"Clear"}
        closeForm = {true}
        formCallBack={(_value)=>{ onFetch(addlineMapper(_value)) }}
        back={()=>{ history.push(`/board/${params.boardId}` ) }}
        headerTitle={"Add Line"}
        isVisible = {true}
        backBtn = {true}
      >
      
        <InputText  title={"Name"}    required={true}   selectedValue={""}             id={"name"} />
        <InputText  title={"Comment"} required={true}   selectedValue={""}             id={"comment"} />
        <InputList  title={"Angle"}   required={true}   selectedValue={[]}             id={"angle"}   options={angleList}  selectLimit={1} />
        <InputList  title={"Grade"}   required={true}   selectedValue={[]}             id={"grade"}   options={gradeList}  selectLimit={1} /> 
        <InputList  title={"Rating"}  required={true}   selectedValue={[]}             id={"rating"}  options={ratings}    selectLimit={1} /> 
        <InputHolds title={"Holds"}   required={true}   selectedValue={selectedValue}  id={"holds"}   options={views}      selectLimit={null} />
      
      </Form>
    
    </div>

  )

  
}


export default AddLine