
const baseURL = "https://hugoluc.com/API/boulder/test"

function getData(_params){

    var fetchParams = { 
        method : _params.method, 
        headers : _params.headers, 
    }

    if(_params.body) fetchParams.body = JSON.stringify(_params.body)

    fetch( baseURL + _params.url, fetchParams )
    .then(res => res.json())
    .then( _params.sucess, _params.error)
}

export default getData