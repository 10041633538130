import { Link } from "react-router-dom";
import Indication from "./indicator.js"

function LineCard(props){

  let iconStyle = {
    width :  "50px",
    color : "gray",
    display : "flex",
    alignItems : "center"
  }


  return( 

    <Link to={`/board/${props.boardId}/line/${ props.id }`}>
      <div className="ListOption">
        
        <div style={{ width :  "150px"  }}>
          
          <Indication {...props} />


        </div>
        <div className="titleContainer">
        
          <div style={{ marginLeft :  "15px"  }} > { props.name }  </div>
        </div>
        
        <div style={iconStyle}> > </div>
      
      </div>
    </Link>
  )
}


export default LineCard