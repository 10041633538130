import { useCookies } from 'react-cookie'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import useFetch from '../hooks/hooks.js';
import  { useState } from 'react';

import UserContext from "../Contexts/UserContext.js"

function GetGoogleId(){

  const setCookie = useCookies(['boulderLogIn'])[1];

  return (
    <div>
       
      <GoogleLogin
        clientId="214353997296-artcjpliv3d6gakq2bp1i3sg2h2227j5.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={(data)=>{ setCookie("boulderLogIn", data.googleId,{ maxAge : 9999999}); }}
        onFailure={(_A)=>{ }}
        cookiePolicy={'single_host_origin'}
      />,

    </div>
    )
  
}
      
function GetUser(props) {

  const cookies = useCookies(['boulderLogIn'])[0];

  const {content,error} = useFetch({ 
    url : `/user/${cookies.boulderLogIn}`, 
    fetchOnMount : true,
    sucess : (data)=>{  props.setUser(data) }
  })
  
  if(content){

    return ( props.children )

  }else if(error){
    return(
      <div> ERROR loading user </div>
    )
  }
  else{
    return(
      <div> LOADING USER </div>
    )
  }
  
}
  


function LogInValidation(props){
  
  const setCookie = useCookies(['boulderLogIn'])[1];
  setCookie("boulderLogIn", "113830080676068864658",{ maxAge : 9999999})
  const cookies = useCookies(['boulderLogIn'])[0];
  const [user, setUser] = useState({});
  
  let value = { user, setUser}

  if(!cookies.boulderLogIn){
    
    return (
      
      <Router>
        <Redirect to="/login"/>     
        <Switch>
          <Route exact path="/login">
            <GetGoogleId/>
          </Route>
        </Switch>
      </Router>
      
      )

  }else{

    return (
      <UserContext.Provider value={ value }>
        
        <Router>
      
          <GetUser setUser={setUser}>
              {props.children}
          </GetUser>
      
        </Router >
      
      </UserContext.Provider>

      )
  }

}

export { LogInValidation, GetUser, GetGoogleId}
