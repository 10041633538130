import React from 'react';
import { useParams, Link } from "react-router-dom";
import  { useState,useContext } from 'react';

//components
import Header from './Header.js'
import FloatingBtn from "../Components/floatingBtn.js" 
import LineCard from "../Components/lineCard.js" 
import SideMenu from "../Components/sideMenu.js"

//context
import UserContext from "../Contexts/UserContext.js"
import { BoardContext } from "../Contexts/BoardContext.js"

function LinesList(props){

  let boardId  = useParams().boardId
  let userContext = useContext(UserContext)  
  let {board} = useContext(BoardContext)
  let userFilters;
  userContext.user.currentViews = board

  if(userContext.user.filters){ 
    userFilters = userContext.user.filters 
  }else{ 
    userFilters = { angle : [], grade : [], rating : [], holds : {}, name : "" }     
  }

  if(userContext.user.filters && Object.keys(userContext.user.filters.holds).length  === 0 && board.board && board.board.views){  
    
    for( let viewId in board.board.views){
      userFilters.holds[viewId] = { holds : {} }
    }
  }

  let [filtersData,setFilterData] = useState(userFilters)
  userContext.user.filters = filtersData

  // ------------ Side menu
  const [sidemenuOpen,setsideMenuStatus] = useState(false)
  function renderSideMenu(){
    
    if(sidemenuOpen){
      return (
        <>
          <SideMenu
            isOpen = {sidemenuOpen}
            setOpen = {setsideMenuStatus}
          />
        </>
      )
    }else{
      return null
    }
    
  }

  //------------lines
  function renderLines(){
    
    if(board && board.list !== undefined){

      return (
        <div className="List" style={ {background: "#FAFAFA" }  } >
  
            <FilteredLines filtersData={filtersData} boardId={boardId} linesList={board.list}/>
            
            <FloatingBtn visible={true} color={"#34206C"} content={ 
              <Link to={ `/board/${boardId}/addLine` }> <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M29.5 18V43" stroke="white" strokeWidth="5" strokeLinecap="round"/> <path d="M42 30.5L17 30.5" stroke="white" strokeWidth="5" strokeLinecap="round"/> </svg> </Link>  
              } 
            />
          
        </div>      
      )
    }else{
      return ( null )
    }



  }

  //----------------------- render --------------------------- 

  return (
    <div style={{  height : "100vh", display: "flex", flexDirection: "column" }}>
      <Header 
          filtersData = {filtersData}
          setFilterData = {setFilterData}
          sideMenuStatus = {sidemenuOpen}
          setSideMenu = {setsideMenuStatus}
        />
      { renderSideMenu() }
      { renderLines() }
    </div>
  )

}


function FilteredLines(props){

  let filterData = props.filtersData

  //-------------------filter funtions
  function filterList(list,value){

    if(list.length === 0 || !value) return true

    if(list.indexOf(value) > -1){
      return true
    }else{
      return false
    }

  }

  function holdsFilters(_filterData,_item){
    
    //return true if nothing is filtered
    for( let viewID in _filterData){

      if(_filterData[viewID].holds && Object.keys(_filterData[viewID].holds).length === 0){
        return true
      }
      
    }

    //check every views from line
    for( const viewId in _filterData){

      // check every hold from 
      for (const property in _filterData[viewId].holds) {
          
        if( Object.keys(_item.views[viewId].holds).indexOf(property) === -1 ){
          return false
        }
        
      }

      
    }
    
    return true
    
  }

  //--------------Filter Lines

  return props.linesList.map( 
    
    (item,index) => {

      
      let filterGrade = filterList( filterData.grade, String(item.grade) ) 
      
      let filterAngle = filterList( filterData.angle, String(item.angle))
      
      let nameFilter = filterList(String(item.name).toLowerCase(),String(filterData.name).toLowerCase())

      let holdsFilter = holdsFilters(filterData.holds,item)

      let filterRating = true

      if(filterGrade && filterAngle && filterRating && nameFilter && holdsFilter){
        return ( <LineCard className="List" boardId={props.boardId} key={index} {...item} />  )
      }else{
        return null
      }


    }
  )

}


export default LinesList


