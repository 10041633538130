import  { useState, useEffect } from 'react';
import getData from '../getData.js';

function useFetch({url,fetchOnMount,method,body,image,sucess,error}){

    const [loading,setLoading] = useState(false);
    const [content,setContent] = useState(null);
    const [errorState,setErrorState] = useState(null);

    //-----------------------------------
    
    useEffect(()=>{ if(fetchOnMount){ onFetch(body) } },[])

    const onFetch = (_body) => {
        
        setLoading(true)
        
        getData({
            body : _body,
            method: method ? method : 'GET',
            headers: image  ? { "Content-Type": "application/octet-stream" } : { 'Content-Type': 'application/json' },
            url,
            sucess : (data)=>{ 
                if (sucess) sucess(data)
                setContent(data)
                setLoading(false)
                setErrorState(false)
            },
        
            error : () =>{
                if (error) error(error)
                setLoading(false)
                setContent(false)
                setErrorState(true)
            } 
        })
    }

    return {loading,content,errorState,onFetch}

}

export default useFetch