//React
import {React, useContext,useState } from 'react';
import {useParams,useHistory} from "react-router-dom";
import { TransformWrapper,TransformComponent } from "react-zoom-pan-pinch";
import useFetch from '../hooks/hooks.js';

//components
import FloatingBtn from "../Components/floatingBtn.js"
import Views from "../Components/views.js"
import ViewsHeader from '../Components/viewsHeader.js';
import Icons from '../Components/icons.js';

//context
import { BoardContext } from "../Contexts/BoardContext.js"

//-----------------------------------------------------------------------------
function HandleZoom(props){

  if(!props.edit){ props.resetTransform() }
  return null

}

function EditViews(props){
  
  let {board,setBoard} = useContext(BoardContext)

  if(board && board.board){
    
    return ( <ViewsPage {...board.board} {...props} board={board} setBoard={setBoard}/> )
        
  }else{

    return (  
      
      <div className="lineWrapper" > 
        {/* FIX ME!!! genderizar corretamente depois do PUT */}
        {/* <ViewsHeader back={()=>{}}/> */}
      </div> 
    ) 

  }

  
};

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------

const ViewsPage = (props) => {

  //variables
  let { boardId,viewId } = useParams();
  let [scale,setScale] = useState(1)
  let [interacting,setInteracting] = useState(false)
  let [selectTimeOut,setSelectTimeOut] = useState({})
  const history = useHistory()
  
  //clone view
  var holds= {}
  var copyView = {}
  Object.assign(holds , {...props.views[viewId].holds})
  copyView.holds = holds
  copyView.imageUrl = props.views[viewId].imageUrl
  copyView.incrementId = props.views[viewId].incrementId
  let [editedView,setEditedView] = useState(copyView) 
  let {onFetch} = useFetch({
    url : `/${boardId}/editView/${parseInt(viewId)}`,
    fetchOnMount: false,
    method : "PUT",
    error : (_e)=>{ 
    
      console.log(_e); 
    
    },
    sucess : (_data)=>{
  
      history.push(`/board/${boardId}/views/${viewId}`)
      props.setBoard(null)

    }
  })
  
  //funcitons
  let btnIcon = ()=>{
    var type = !props.edit ? "edit" : "save"
    return ( <Icons type={type}></Icons> )
  }
  let btnFunc = ()=>{
  
    if(props.edit){
      onFetch({
        boardid : boardId,
        viewId : viewId,
        holds : editedView.holds,
        imageUrl : editedView.imageUrl
      })
    }else{
      history.push(`/board/${boardId}/views/${viewId}/edit`)
    }

  }
  function back(){
    history.push(`/board/${boardId}/edit`)
  }
  const handleSlideChange = (_e)=>{ 
    history.push(`/board/${boardId}/views/${_e.activeIndex}`) 
  }
  
  const handleDrag = (_e) => {  
    
    console.log(_e);
    if(_e.hasMoved){
      clearTimeout(selectTimeOut)
      editedView.holds[_e.id] = _e.position
      setEditedView(editedView)  
    }

  }

  //--------------------------->>>>>
  const onZoomStop = () =>{
    console.log("STOP");
    setInteracting(false)
  }

  const handleZoom = (_event) => { 
    clearTimeout(selectTimeOut)
    setInteracting(true)
    if(_event && _event.state) setScale(_event.state.scale) 
  }

  var onTouchEnd = (_e)=>{
    clearTimeout(selectTimeOut)
    if(props.onTouchEnd) props.onTouchEnd()
  }

  //render
  return (

    <div className="lineWrapper" >   
      
      <ViewsHeader name={`View ${viewId}`} back={back}/>
      <FloatingBtn content={btnIcon()} clickFunc={btnFunc} visible={true} color={"#34206C"} /> 

      <TransformWrapper 
        alignmentAnimation={{disabled : true }} 
        disabled={!props.edit} 
        panning={{ disabled : true }} 
        doubleClick={{disabled : true}}
        onZoom={handleZoom}
        onZoomStop={onZoomStop}
        onPinchingStart= { ()=>{ handleZoom()} }
        onZoomStart= { ()=>{ handleZoom()} }
        centerZoomedOut={true}
      >
        {({ resetTransform }) => (
          <>
            <HandleZoom resetTransform={resetTransform} edit={props.edit} ></HandleZoom>
            <TransformComponent>
              <Views 
              
              //hooks
                selectTimeOut= {selectTimeOut}
                setSelectTimeOut = {setSelectTimeOut}
                interacting = {interacting}
                setInteracting = {setInteracting} 

              //handlres
                onTouchEnd = {onTouchEnd}
                onDrag={handleDrag}
                changeSlide={ handleSlideChange }

              // properties
                selectedHolds={props.views}
                selectedView={viewId}
                enableSwipe={!props.edit} 
                edit={false} 
                drag={props.edit} 
                disabled={!props.edit} 
                scale={scale}/>          
            </TransformComponent>
          </>

        )}

      </TransformWrapper>


    </div>
  )
  
}

export default EditViews 