import Holds from "./holds.js";
import  { useState } from 'react';

function Board(props) {

  //---------------------------------------------------------variables
  let [loaded,setLoaded] = useState(false)
  let [divHeight,setDivheight] = useState(0)
  let [divWidth,setDivWidth] = useState(0)
  let [imgSize,setImgsize] = useState({ with : 0, height : 0, ratio : 0})
  let targetheight = divWidth/imgSize.ratio
  
  var transformBoard = loaded ? `translateY(${( divHeight - targetheight) / 2}px)`: ""
  
  const selectedHolds = useState(()=>{

    var holds = {}

    for(let holdIds in props.holds){ 
      if(props.holds[holdIds].type !== 3) holds[holdIds] = props.holds[holdIds].type
    }

    Object.keys(props.holds).map((value,index)=>{
      if(props.holds[value].type !== 3) holds[value] = props.holds[value].type
      return null
    })
    return holds
  })[0]

  var style = {
    width : "100%",
    height : "100%",
    backgroundRepeat : "no-repeat",
    backgroundSize: "contain",
  }

  //-----------------------------------------------------FUNCTIONS

  const renderHolds  = (value,index) =>{
    
    var changeType = (type)=>{
      if(props.edit){
    
        if( type === 3){ 
          delete selectedHolds[value]             
        }else{ 
          selectedHolds[value] = type
        }
      
        props.onTypeChange(selectedHolds,props.viewId);
    
      }
    }
    
    return (
      <Holds 
        {...props}
        interaction = { props.interacting }
        key={value}
        id={value} 
        hold={props.holds[value]} 
        onTypeChange={ changeType }
      />
    )
  }

  //-------------------------------------------------- BOARD RENDERS
  var renderBoard = ()=>{

    if(loaded){
      
      style.backgroundImage =  `url(${props.imageUrl})`  

      return (
        <>
          <div className="holdsWrapper" > { Object.keys(props.holds).map(renderHolds) } </div>      
          <div style={style}  alt={""}></div>  
          <img alt="" style={{ display : props.slides ? "block" : "none" }} src={props.imageUrl}></img>
        </>
      )

    }else{
     
      return (
        // ADD LOADING COMPONENT
        <img alt="" onLoad={ (_e)=>{  
          setLoaded(true); 
          setImgsize({
            width : _e.target.width,
            height : _e.target.height,
            ratio : _e.target.width/_e.target.height
          })

        } } style={{ display : "block" }} src={props.imageUrl}></img>
        )      
      }
      
  }

  //-----------basic Return
  console.log(transformBoard);
  return (
    <div  style={{ transform : transformBoard, background : "black" , height : `calc(100% - ${(divHeight - targetheight) / 2}px)`}}  className={"board"}  ref={(id)=>{ 
        
        if(id) { 
          setDivheight(id.clientHeight); 
          setDivWidth(id.clientWidth)
        } 

      } } >
      {renderBoard()}
    </div> 
    
  )

}



export default  Board