
function settings(){
    
	this.gradesIndex ={
		French : {
			"5" : 0 ,
			"5+" : 1 ,
			"6A" : 2 ,
			"6A+" : 3 ,
			"6B" : 4 ,
			"6B+" : 5 ,
			"6C" : 6 ,
			"6C+" : 7 ,
			"7A" : 8 ,
			"7A+": 9 ,
			"7B" : 10,
			"7b+" : 11,
			"7C" : 12,
			"7C+" : 13,
			"8A" : 14,
			"8A+" : 15,
			"8B" : 16,
			"8B+" : 17,
			"8C" : 18,
			"8C+" : 19,
			"9A" : 20 
		},
		
		Vscale : ["Vb","V0","V1","V1"]
	} 

	this.currentGradeSystem = "French"

	this.angles  = {
		"0" : 0,
		"5" : 1,
		"10" : 2,
		"15" : 3,
		"20" : 4,
		"25" : 5,
		"30" : 6  
	}

}

settings.prototype.getGradeByIndex = function(_gradeIndex){

	var grade;
	var gradeList = this.getCurrentGradeList()
	var keys = Object.keys(gradeList)
	
	for (let index = 0; index < keys.length; index++) {

		var gradeName = keys[index]
		var gradeId = gradeList[gradeName]
		
		if(gradeId === _gradeIndex){
			grade = gradeName
			break
		}
		
	}
	
	
	return grade
    
}

settings.prototype.getAngleByIndex = function(_angleIndex){

	var angle;
	
	Object.keys(this.getAngleList()).map((value,index)=>{

			if(this.getAngleList()[value] === _angleIndex){
					angle = value
			}

			return null

	})

	return angle
	

}

settings.prototype.setGradeSystem = function(_type){
	this.currentGradeSystem = _type
}

settings.prototype.getCurrentGradeList = function(_type){
	return this.gradesIndex[this.currentGradeSystem]
}

settings.prototype.getAngleList = function(_type){
	return this.angles
}

export default settings