import BackBtn from "../Components/BackBtn.js" 
import Indication from "./indicator.js"
import Rating from "../Components/Rating.js"


function LineHeader(props){
  
  return (
    <div className={"lineHeader"}>
      
      <BackBtn back={props.back} fadeIn={true}/>
      
      <div className={"nameContainer"} style={{ marginRight : "20px"}}>
        {props.name}
        <Rating rating = { props.rating } />
      </div>
      
      <div style={{position : "absolute", right : "45px"}}>
        <Indication {...props} bg="white"/>
      </div>

    </div>
  )

}

export default LineHeader