import Draggable from 'react-draggable'; 
import  { useState } from 'react';

function Holds(props) {

	let marSize = 10
	let minSize = 5
	let colors = ["#FF94B4","#47C095","#EFC75F","rgba(255,255,255,0.3)","#EFC75F"]

	const [selected,setSelected]= useState(false)
	const [type,setType]= useState(props.hold.type !== undefined ? props.hold.type : 3)
	const [size,setSize]= useState(type === 3 ?  minSize : marSize)
	var pixelPos = deNormalize(props.hold.x,props.hold.y,size)

	const [preDragPos,setPreDragPos]= useState({...pixelPos})

	let distanceMoved = { x : 0, y : 0 }
	let hasMoved = false;
	
	//style
	const holdStyle = {  
		width : size, 
		height : size, 
		border : `${colors[type]} solid 5px`
	}
	holdStyle.left = pixelPos.x - (size/2) 
	holdStyle.top = pixelPos.y - (size/2) 

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  let changeType = () =>{ 
    if(props.edit){
      const newSize = (type+1)%4
      setType(newSize)
      setSize(newSize === 3 ?  minSize : marSize)
      if(props.onTypeChange)  props.onTypeChange(newSize)
	  } 
  }

	function handleDrag(_e,_ui){

		distanceMoved.x = distanceMoved.x + _ui.deltaX
		distanceMoved.y = distanceMoved.y + _ui.deltaY

		if(Math.abs(distanceMoved.y) > 1 && Math.abs(distanceMoved.x) > 1){
			hasMoved = true
		}

		if(props.interacting) return false
		if(!selected) return false

		//callback
		var normalPos = normalize(_ui.x,_ui.y,size)
		if(props.onDrag) props.onDrag( 
			{ id : props.id, 
				position : { x : props.hold.x + normalPos.x, y : props.hold.y + normalPos.y },
				hasMoved : hasMoved
			} 
		)

	}

	var selectHold = () =>{		
		if(props.interacting) return false
		let holdtype = selected ? 3 : 4
		setType(holdtype) 
		setSelected(!selected)
	}

	var onTouchStart = (_e)=>{
		if(props.interacting) return false
		props.setSelectTimeOut(setTimeout(selectHold,1000))		
		//callback
		if(props.touchStart) props.touchStart(_e)
	}

	var onTouchEnd = (_e)=>{
		setPreDragPos({
			x : preDragPos.x + distanceMoved.x,
			y : preDragPos.y + distanceMoved.y
		})
		distanceMoved = { x : 0, y : 0 }
		hasMoved = true
		if(props.onTouchEnd) props.onTouchEnd(_e)

	}

	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	if(props.drag){
		
		var scale = props.scale > 1 ? props.scale : 1

		return(
			<Draggable onDrag={handleDrag} scale={scale} >
				<div id={props.id} onTouchStart={ onTouchStart } onTouchEnd={onTouchEnd} className="hold" style={holdStyle} onClick={changeType} ></div>
			</Draggable>
		)


		
		
	}else{
		
		return(
		
			<div className="hold" id={props.id} style={holdStyle} onClick={changeType} ></div>
		
		)
	}

}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


var normalize = (_x,_y,_size) =>{
	
	const width  = window.innerWidth;
	const height = width * 1.4

	let x = (_x / width) 
	let y = (_y / height) 

	return {x : x, y : y}

}

var deNormalize = (_x,_y,_size) =>{
	
	const { innerWidth: width } = window;
	let x = (_x * width) 
	let y  = (_y * width * 1.4) 

	return { x : x , y : y }

}


export default Holds