import React from 'react';
import  { useState,useContext,useEffect } from 'react';
import UserContext from "../Contexts/UserContext.js"
import { BoardContext } from "../Contexts/BoardContext.js"
import { useCookies } from 'react-cookie'
import { useHistory,useParams } from "react-router-dom";

function SideMenu(props){
  
  var {setBoard} = useContext(BoardContext)
  let userContext = useContext(UserContext)
  const setCookie = useCookies(['lastBoardId'])[1];
  const history = useHistory()
  const [isVisible,setVisible] = useState(false)
  const [boardID,setBoardID] = useState(useParams().boardId)

  useEffect(()=>{ 
    setTimeout(()=>{  setVisible(true) },1) 
  },[] )

  function transitionEnd(_e){
    if(!isVisible) props.setOpen(false)
  }

  function selectBoard(_boardId){
    
    setVisible(false)
    setBoardID(_boardId)
    
    if(parseInt(boardID) !== parseInt(_boardId)){   
     
      setCookie("lastBoardId",_boardId,{maxAge : 9999999999})
      history.push(`/board/${_boardId}`)
      setBoard(null)
      
    }

  }

  var editBoard = false
  var bgClass = isVisible ? "sideMenuBg open" : "sideMenuBg closed"
  var Class = isVisible ? "sideMenu open" : "sideMenu closed"

  return (
    
    <div className={props.isOpen ? "sideMenuContainer open" : "sideMenuContainer closed" }>
      <div className={Class} onTransitionEnd={ (_e)=>{ transitionEnd(_e)  }}> 
      
      
      <div className='titleContainer'>
        <div className='title'>
          My Bords
        </div>
      </div>


      {/* BTNS list */}
      { Object.keys(userContext.user.boardsData).map( 
        (_i,key)=> {   

          return ( 
          
            <div className='boardBtn' key={_i} onClick={ ()=>{  if(!editBoard) selectBoard(_i) }}>  
            <div> {userContext.user.boardsData[key].name} </div>
            <div className="editIconContainer" onClick={
              ()=>{ 
                editBoard = true
                history.push(`/board/${_i}/edit`)
              }}
            >

              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4044 3.08825L12.6643 0.510192C12.3067 0.194141 11.8381 0.0127958 11.3477 0.000651813C10.8572 -0.0114921 10.3791 0.146413 10.0043 0.444329L1.00421 8.91239C0.680976 9.21909 0.479713 9.62108 0.434208 10.0509L0.004203 13.9744C-0.00926819 14.1122 0.00973739 14.2511 0.0598648 14.3812C0.109992 14.5113 0.190007 14.6294 0.294206 14.7271C0.387647 14.8143 0.498465 14.8833 0.620304 14.9301C0.742142 14.977 0.872606 15.0007 1.00421 15H1.09422L5.26426 14.6424C5.72106 14.5996 6.14831 14.4103 6.47428 14.1061L15.4744 5.63807C15.8237 5.29085 16.0125 4.8275 15.9994 4.34955C15.9862 3.8716 15.7723 3.41804 15.4044 3.08825V3.08825ZM5.08426 12.7607L2.08423 13.0241L2.35423 10.2014L8.00429 4.95122L10.7043 7.49164L5.08426 12.7607ZM12.0043 6.23084L9.32431 3.70924L11.2743 1.82745L14.0044 4.39609L12.0043 6.23084Z" fill="#34206C"/></svg>
            
            </div>
            
          </div> 
        
          ) 
        })
      }
      
      </div>
      <div className = {bgClass} onClick={ ()=>{ selectBoard(boardID) }}></div>
    </div>
    
  )
      
}

export default SideMenu
