import PopUp from "../popUp.js"
import  { useState, useEffect } from 'react';


function FormInput(props){
  
  const [visible,setVisible] = useState(false)
  const [popUpOpen,openPopUp] = useState(false)

  function back(){
    props.closePopUp()
    openPopUp(false)
  }

  let delay = props.visible ? props.delay : props.delayOut
  useEffect(()=>{  
    setTimeout(()=>{ 
      setVisible(props.visible)   
    },(delay + 1))  
  })

  var icon = !props.children ? 
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6.48645" width="3.02703" height="16" fill="white"/><rect x="16" y="6.48651" width="3.02703" height="16" transform="rotate(90 16 6.48651)" fill="white"/></svg> : 
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4044 3.82995L12.6643 1.43124C12.3067 1.13718 11.8381 0.968449 11.3477 0.957149C10.8572 0.94585 10.3791 1.09277 10.0043 1.36996L1.00421 9.24894C0.680976 9.5343 0.479713 9.90833 0.434208 10.3082L0.004203 13.9588C-0.00926819 14.087 0.00973739 14.2163 0.0598648 14.3373C0.109992 14.4584 0.190007 14.5683 0.294206 14.6592C0.387647 14.7403 0.498465 14.8045 0.620304 14.8481C0.742142 14.8916 0.872606 14.9137 1.00421 14.9131H1.09422L5.26426 14.5804C5.72106 14.5405 6.14831 14.3644 6.47428 14.0814L15.4744 6.2024C15.8237 5.87934 16.0125 5.44822 15.9994 5.00352C15.9862 4.55882 15.7723 4.1368 15.4044 3.82995ZM5.08426 12.8295L2.08423 13.0746L2.35423 10.4483L8.00429 5.56333L10.7043 7.92702L5.08426 12.8295ZM12.0043 6.75393L9.32431 4.40775L11.2743 2.65686L14.0044 5.04682L12.0043 6.75393Z" fill="white"/></svg>

  return (
    <>
      <div className="formOption"  style={{  background : props.children? "rgba(255,255,255,0.03)" : "",  opacity : visible ? "1" : "0",  transform : visible ? "translateY(0)" : "translateY(-10px)"  }} onClick={ ()=>{ props.openPop(); openPopUp(true)  }} >
        
        <div className="title">
        
          <div> {props.title} </div>
          
          <div className="icon" style={{}}>
           { icon }
          </div>
        </div>

        <div className="selectedValues" >
          { props.children }
        </div>
        
      </div>        

      <PopUp
        content={ props.popUpContent}
        rendering={popUpOpen}
        fullScreen={props.fullScrennPopUp}
        back={back} 
        okFunc={props.okFunc}
      />

    </>

  )

}

export default FormInput