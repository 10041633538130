function Rating(props){

  let array = []

  for (let i = 0; i < 5; i++) {
    
    var bgColor = i < props.rating ? "#F8D376" : "gray"

    array.push(
      <svg key={i} style={{marginLeft : "5px"}} width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9067 0.907369L8.17396 3.3063C8.38108 3.69839 8.75107 3.97874 9.18458 4.07207L11.8122 4.63776C12.1832 4.71762 12.3335 5.16543 12.0858 5.45292L10.2027 7.63842C9.93162 7.9531 9.80312 8.36611 9.84785 8.77906L10.16 11.6606C10.2023 12.0518 9.79708 12.3364 9.44348 12.1637L7.12281 11.0305C6.70744 10.8276 6.22175 10.8276 5.80639 11.0305L3.48572 12.1637C3.13211 12.3364 2.72685 12.0518 2.76923 11.6606L3.08135 8.77906C3.12608 8.36611 2.99757 7.9531 2.72645 7.63842L0.843434 5.45292C0.595732 5.16543 0.746012 4.71762 1.117 4.63776L3.74462 4.07207C4.17813 3.97874 4.54812 3.69839 4.75524 3.3063L6.02249 0.90737C6.21017 0.552096 6.71903 0.552097 6.9067 0.907369Z" fill={bgColor} stroke={bgColor}/>
      </svg>
    ) 
  }

  return ( 
    <div style={{ fontSize : "initial", marginTop : "7px"}}>
      {array }
    </div>
    )

}

export default Rating