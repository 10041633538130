import  { useState, useEffect } from 'react';

function FloatingBtn(props){

  const [visible,setVisible]= useState(false)
  
  useEffect(()=>{  
    setTimeout(()=>{ 
      setVisible(props.visible)  
    },(props.delay + 1))  
  })
  
  return(
    <div onClick={props.clickFunc} style={{ transform : visible ? "scale(1)" : "scale(0)", background : props.color ? props.color : "#ffffff"  }} className="flaotinBtn"> 
     <div className="contentWrapper">
      {props.content} 
     </div>
    </div>
  )

}

export default FloatingBtn