import {Form} from "../Components/form.js"
import settings from "../settings.js"
import { useParams,useHistory } from "react-router-dom";
import { InputList,InputHolds,InputText } from "../Components/FormInputs/allFormInputs.js"
import useFetch from '../hooks/hooks.js';
import  { useContext } from 'react';

//context
import { BoardContext } from "../Contexts/BoardContext.js"
import { LineContext } from "../Contexts/LineContext.js"

const set = new settings()
const gradeList = set.getCurrentGradeList()
const angleList = set.getAngleList()

function EditLine(props){
  
  //context
  let {line,setLine} = useContext(LineContext)
  var {board,setBoard} = useContext(BoardContext)

  let { boardId,lineId } = useParams();
  
  const history = useHistory()
  
  let {onFetch} = useFetch(
    
    {
      url : `/${boardId}/editLine/${lineId}`,
      fetchOnMount: false,
      method : "PUT",
      error : ()=>{},
      sucess : (_data)=>{
        setBoard(null)
        setLine(null)
        setTimeout( 
          ()=>{  history.push(`/board/${boardId}/line/${lineId}`) },1
        )
      }
    }

  )

  if(board.board && line && line.line){ 
    
    //get views
    let views = board.board.views 
    
    let selectedValue = getHolType({...line.line.views})

    const ratings = {
      "*" : 1,
      "**" : 2,
      "***" : 3 ,
      "****" : 4,
      "*****" : 5,
    }

    const addlineMapper = (_value) => {

      let outPut = {

        "name": _value.name,
        "angle": _value.angle[0],
        "grade": _value.grade[0],
        "rating": _value.rating,
        "date": Date.now(),
        "id" : lineId,
        "comment" : _value.comment,
        "views" : _value.holds

      }

      return outPut

    }


    return (
      
      <div className = "AddlineWrapper">
        
        <Form
          floatingContent = {"OK"}
          floatingType = {"white"}
          actionName = {"Clear"}
          closeForm = {true}
          formCallBack = {(_value)=>{  
            onFetch(addlineMapper(_value))  
          
          }}
          back={()=>{ history.push(`/board/${boardId}/line/${lineId}` ) }}
          headerTitle = {"Edit Line"}
          isVisible = {true}
          backBtn = {true}
        >
          <InputText  title={"Name"}    required={true}   selectedValue={line.line.name}        id={"name"}                                            />
          <InputHolds title={"Holds"}   required={true}   selectedValue={selectedValue}         id={"holds"} options={{...views}}  selectLimit={null}  />
          <InputList  title={"Angle"}   required={true}   selectedValue={[line.line.angle+""]}  id={"angle"} options={angleList}   selectLimit={1}     />
          <InputList  title={"Grade"}   required={true}   selectedValue={[line.line.grade+""]}  id={"grade"} options={gradeList}   selectLimit={1}     /> 
          <InputList  title={"Rating"}  required={true}   selectedValue={[line.line.angle+""]}  id={"rating"} options={ratings}     selectLimit={1}     /> 
          <InputText  title={"Comment"} required={true}   selectedValue={line.line.comment}     id={"comment"}                                         />
        
        </Form>
      
      </div>

    )

  }else{

    return (
      <div>
        error
      </div>
    )

  }

}


function getHolType(views){

  const parsedView = {}
  
  for (let viewId in views) {

    const { imageUrl, holds } = views[viewId]

    parsedView[viewId] = { imageUrl, holds: {} }

    for (let holdId in holds) {
      
      Object.assign(parsedView[viewId].holds, {
        [holdId]: holds[holdId].type
      })
    }

  }

  return parsedView

}



export default EditLine