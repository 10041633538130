import  { useState } from 'react';
import Views from  "../views.js"
import FormInput from './FormInput.js';


function InputHolds (props){

  let initialSelectedValue = {}
  
  for(let views in props.selectedValue){
    initialSelectedValue[views] = {...props.selectedValue[views]}
  }
  
  let initialTmpValues = {}
  for(let views in props.selectedValue){
    initialTmpValues[views] = {...props.selectedValue[views]}
  }

  let selectedValue = useState({...initialSelectedValue})[0]
  let [temporaryValue,setTemporaryValue] = useState({...initialTmpValues})

  let newViews = props.options
  let content = <Views views={newViews} selectedHolds={{...temporaryValue}} edit={true} drag={false} onTypeChange={ ()=>{} }/>

  const selectNewValue = (views) =>{

    for(let views in temporaryValue){
      selectedValue[views] = {...temporaryValue[views]}
    }
    
    setTemporaryValue({...initialTmpValues})
    
    props.dataChangeCallBack(selectedValue) 

  }
  
  const closePopUp = ()=>{
  
    for(let views in selectedValue){
      initialTmpValues[views] = {...selectedValue[views]}
    }  
  
    setTemporaryValue({...initialTmpValues})
  
  }

  const selectedValueContent = ()=> {

    const bgcolors = [
      "#E56EA0",
      "#47C095",
      "#DBB962",
    ]
    
    const selectedkeys = Object.keys(selectedValue[0].holds)
    
    if(selectedkeys.length === 0){ return null
    }else {
      
      return (
        
        <div style={{ display : "flex", flexWrap : "wrap"}}>
            
            {
              selectedkeys.map((value,index)=>{      
                
                return (
                  <div key={index} className="value" style={{
                    background : bgcolors[selectedValue[0].holds[value]],
                    color : "white"
                  }}> {value} </div>
                )
              })
            }
          </div>
        )

    }


  }

  const openPop = () =>{}

  return (

    <>
      <FormInput {...props}
       fullScrennPopUp={true}
       okFunc = { selectNewValue }
       closePopUp= { closePopUp }
       openPop = { openPop }
       popUpContent = {content}
      >
    
        {selectedValueContent()}

      </FormInput>
    </>

  )
  
}

export default InputHolds