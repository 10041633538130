import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import  Board from "../Components/board.js"
import SliderCounter from "../Components/sliderCounter.js"
import { useState } from 'react';

function Views(props) {

    //ˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆMETHODSˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆˆ

    const [viewIndex,setViewIndex] = useState(0)
    
    const changeSlide = (_e) =>{ 
      setViewIndex(_e.activeIndex)
      if(props.changeSlide) props.changeSlide(_e)
    }
    
    const onTypeChange = (newSelectedHolds,viewId) => {

      selectedHolds[viewId].holds = newSelectedHolds
      props.onTypeChange(selectedHolds)

    }
    
    const mergeHolds = (_selectedHolds,_views)=>{
  
      for(let viewId in _views){

        for( let holdId in _views[viewId].holds){
          
          if(_selectedHolds[viewId] && _selectedHolds[viewId].holds[holdId] !== undefined){
          
            _views[viewId].holds[holdId].type = _selectedHolds[viewId].holds[holdId]
          
          }else{
          
            _views[viewId].holds[holdId].type = 3
          
          } 
    
        }
      }
    
      return _views
    
    }
    
    const renderSlides = (value,index) =>{

      return (
        <SwiperSlide key={index}> 
          <Board {...props} {...mergedViews[0]} onTypeChange={onTypeChange} viewId={index} slides={true}/>
        </SwiperSlide>
      )
      
    }

  //--------------------------------DECLARATIONS--------------------------------

  var selectedHolds = {...props.selectedHolds}
  var views = props.views ? {...props.views} : {...props.selectedHolds}
  let mergedHolds = props.views ? mergeHolds(selectedHolds,views) : props.selectedHolds
  const mergedViews = useState(mergedHolds)[0]

  //--------------------------------RENDER--------------------------------
  if(Object.keys(views).length > 1){
  //------SLIDES  
    return (  
      <>
        <SliderCounter views={mergedViews} index={viewIndex}  ></SliderCounter>
        <Swiper onSlideChange = {changeSlide} allowSlideNext={props.enableSwipe} allowSlidePrev={props.enableSwipe}>
          { Object.keys(mergedViews).map(renderSlides) }
        </Swiper>
      </>
      
    )

  }else{
  //------NO SLIDES
    return ( <Board {...props} {...mergedViews[0]} onTypeChange={onTypeChange} viewId={"0"} slides={false} /> )

  }

}

export default Views