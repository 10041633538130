import BackBtn from "../Components/BackBtn.js" 

function viewsHeader(props){
  
  return (
    <div className={"viewsHeader"}  >
      
      <BackBtn back={props.back} fadeIn={true}/>
      
      <div className={"nameContainer"} style={{ marginRight : "20px"}}>
        {props.name}
      </div>

    </div>
  )

}

export default viewsHeader