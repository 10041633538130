import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './swiper.css';
import App from './App';

// window.addEventListener("touchend",(_e)=>{ console.log(_e.target); })

var t = setTimeout(()=>{},11)
console.log(t);

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);