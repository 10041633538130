import './App.css';
import { CookiesProvider } from 'react-cookie';
import  { LogInValidation,} from './pages/Login.js'
import Routes from'./Routes.js'


function App() {
  
  return (
    
    <CookiesProvider>
      <LogInValidation>          
          <Routes/>
      </LogInValidation>
    </CookiesProvider>

  );
}


export default App;
