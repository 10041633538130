import settings from "../settings.js"

const set = new settings()

function Indication(props){
  
  let rightStyle = props.bg ? { background : "white", color : "#34206C" } : {}
  let leftStyle = props.bg ? { background : "white", color : "#34206C", marginRight : "10px" } : { marginRight : "10px" }
  

  return (

    <div className="indicatorContainer">
          
      <div className="indicator" style={leftStyle} >
        <div className="title"> Angle </div> 
        { set.getAngleByIndex(props.angle) }
      </div>
      
      <div className="indicator" style={rightStyle} > 
        <div className="title"> Grade </div> 
        { set.getGradeByIndex(props.grade) }
      </div>
    
    </div>

  )

}

export default Indication