import SelectOption from "../selectOption.js";
import  { useState } from 'react';
import FormInput from './FormInput.js';

function InputList (props){
  
	let [selectedValue,setSelectedValue] = useState([...props.selectedValue])
	let [temporaryValue,settemporaryValue] = useState([...selectedValue])

	function selectTemporaryValue(_state,_value){
		
		var indexOf = temporaryValue.indexOf(String(_value))

		if(!props.selectLimit || temporaryValue.length < props.selectLimit || indexOf >= 0 ){

			
			if(indexOf >= 0){  
			
				temporaryValue.splice([indexOf],1)
				settemporaryValue([...temporaryValue])
						
			}else{ 
			
				temporaryValue.push(String(_value))
				settemporaryValue([...temporaryValue])
			
			}


		}

	}

	const selectNewValue = () =>{

		selectedValue = [...temporaryValue]
		setSelectedValue(selectedValue)
		props.dataChangeCallBack(temporaryValue)

	}
  
	const closePopUp = ()=>{
		settemporaryValue([...selectedValue])
	}
  
	const openPop = ()=>{}
  
	const getPopUpContent = ()=>{

		let popUpContent = Object.keys(props.options).map(
			
			(value,index)=>{
			
				return (
				
					<SelectOption
						key={index} 
						value={ props.options[value] } 
						state={ temporaryValue.indexOf(String(props.options[value])) > -1 ? true : false } 
						label={value}
						available={true}
						clickFunc={ selectTemporaryValue } 
					/>

				)

			}

		)
	
		return popUpContent

	}

	const getSelectedValueContent = ()=>{

		let selectedValueContent;

	if(selectedValue.length > 0){

		selectedValueContent = (
		
		<div style={{ display : "flex", flexWrap : "wrap"}}>
			
			{

				Object.keys(props.options).map( (value,index)=>{   
				
						if( selectedValue.includes(props.options[value].toString()) ){

							return (
								<div key={index} className="value" > {value} </div>
							)

						}else{ return null }
				})

			}
			</div>
		)

	}else{
		selectedValueContent = null
	}
  
	return selectedValueContent

	}

	return (
		<>
			<FormInput {...props}
				fullScrennPopUp={false}
				setSelectedValue={setSelectedValue}
				selectedValue={selectedValue}
				okFunc = { selectNewValue }
				closePopUp= { closePopUp }
				openPop = { openPop }
				popUpContent = {getPopUpContent()}
			>
		
				{getSelectedValueContent()}

			</FormInput>
		</>
	)
  
}

export default InputList

