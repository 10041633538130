//React
import { React } from 'react';

function SliderCounter(props){

  // console.log(Object.keys(props.views).length);

  return (
    <div className='sliderCounter'>
      View {props.index+1} / {Object.keys(props.views).length}
    </div>
  )

}

export default SliderCounter