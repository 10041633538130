import {Form} from "../Components/form.js"
import { useParams,useHistory } from "react-router-dom";
import { InputText,InputViews } from "../Components/FormInputs/allFormInputs.js"
import useFetch from '../hooks/hooks.js';
import  { useState } from 'react';

function EditBoard(props){
  
  
  //context
  var [board,setBoard] = useState(false)
  let { boardId } = useParams();
  const history = useHistory()
  

  useFetch({ 
    url : `/board/${boardId}`,
    fetchOnMount : true,
    sucess : (data)=>{  
      setBoard(data)
    
    }
  })

  // let {editBoard} = useFetch(
    
  //   {
  //     url : `/${boardId}/editLine}`,
  //     fetchOnMount: false,
  //     method : "PUT",
  //     error : ()=>{},
  //     sucess : (_data)=>{
  //       setBoard(null)
        
  //       setTimeout( 
  //         ()=>{  history.push(`/board/${boardId}/edit}`) },1
  //       )
  //     }
  //   }

  // )
  

  if(board){ 
    
    //get views
    // let views = board.views 

    return (
      
      <div className = "AddlineWrapper">
        
        <Form
          floatingContent = {"OK"}
          floatingType = {"white"}
          actionName = {"Clear"}
          closeForm = {true}
          btnContent = { 
            <div>
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 5.19444C0 3.81679 0.54727 2.49557 1.52142 1.52142C2.49556 0.547271 3.81679 1.88719e-07 5.19444 1.88719e-07H24.0153C24.8218 -0.000199773 25.6204 0.158507 26.3655 0.467053C27.1106 0.775599 27.7877 1.22794 28.3579 1.79822L32.2018 5.64211C33.354 6.79433 34 8.35644 34 9.98467V28.8056C34 30.1832 33.4527 31.5044 32.4786 32.4786C31.5044 33.4527 30.1832 34 28.8056 34H5.19444C3.81679 34 2.49556 33.4527 1.52142 32.4786C0.54727 31.5044 0 30.1832 0 28.8056V5.19444ZM5.19444 2.83333C3.89111 2.83333 2.83333 3.89111 2.83333 5.19444V28.8056C2.83333 30.1089 3.89111 31.1667 5.19444 31.1667H5.66667V21.25C5.66667 20.1228 6.11443 19.0418 6.91146 18.2448C7.70849 17.4478 8.7895 17 9.91667 17H24.0833C25.2105 17 26.2915 17.4478 27.0885 18.2448C27.8856 19.0418 28.3333 20.1228 28.3333 21.25V31.1667H28.8056C30.1089 31.1667 31.1667 30.1089 31.1667 28.8056V9.98467C31.1667 9.10633 30.8191 8.26578 30.1977 7.64622L26.3538 3.80233C25.8658 3.31352 25.2369 2.98985 24.5556 2.87678V8.02778C24.5556 8.5859 24.4456 9.13855 24.232 9.65418C24.0185 10.1698 23.7054 10.6383 23.3108 11.033C22.9161 11.4276 22.4476 11.7407 21.932 11.9543C21.4163 12.1678 20.8637 12.2778 20.3056 12.2778H11.8056C10.6784 12.2778 9.59738 11.83 8.80035 11.033C8.00332 10.236 7.55556 9.15495 7.55556 8.02778V2.83333H5.19444ZM25.5 31.1667V21.25C25.5 20.8743 25.3507 20.5139 25.0851 20.2483C24.8194 19.9826 24.4591 19.8333 24.0833 19.8333H9.91667C9.54094 19.8333 9.18061 19.9826 8.91493 20.2483C8.64926 20.5139 8.5 20.8743 8.5 21.25V31.1667H25.5ZM10.3889 2.83333V8.02778C10.3889 8.80978 11.0236 9.44444 11.8056 9.44444H20.3056C20.6813 9.44444 21.0416 9.29519 21.3073 9.02951C21.573 8.76384 21.7222 8.4035 21.7222 8.02778V2.83333H10.3889Z" fill="#34206C"/></svg>
            </div>
          }

          formCallBack = {(_value)=>{  
            //onFetch to update baoprd
            history.push(`/board/${boardId}`)

          }}
          back={()=>{ history.push(`/board/${boardId}`) }}
          headerTitle = {"Edit Board"}
          isVisible = {true}
          backBtn = {true}
        >

          <InputText title={"Name"} required={true} selectedValue={board.name} id={"name"} />
          <InputViews title={"Views"} required={true} selectedValue={""} id={"views"} views={board.views} />
        

        </Form>
      
      </div>

    )

  }else{

    return (
      <div style={{ width : "100%", height : "100%", background : "#34206C",position : "absolute" }}></div>
    )

  }

}



export default EditBoard