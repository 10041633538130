import  { useState, useEffect } from 'react';

function PopUp(props){

  const [visible,setVisible]= useState(false)
  const [rendering,setRendering]= useState(false)

  useEffect(()=>{  

    if(props.rendering){
      setRendering(true)
      setTimeout(()=>{
        setVisible(true)
      },10)
    }
      
    },[props.rendering])
    
    function back(){  
      
      setVisible(false)
      props.back() 

      setTimeout( ()=> { 
        setRendering(false)
      }, 200 )

  }

  if(!rendering){
    return null
  }

  let finalHeight;
  let finalWidth;
  if(props.fullScreen){
    finalHeight = "100%"
    finalWidth = "100%"
  }else{
    finalHeight = "calc(100% - 150px)"
  }

  return (

    <div className="popUpWrapper" style={{opacity : visible ? "1" : "0"}}   >
      
      <div 
        className="popUp" 
        style={
          {
            height :  visible ? finalHeight : "calc(100% - 200px)",
            width : finalWidth
      
          }
        }
      >
        
        <div className="top" onClick={ ()=> { back() }} > </div>
        <div className="content"> {props.content} </div>
        <div className="bottonBtn" onClick={ ()=>{ props.okFunc(); back()}}> Ok </div>
      </div>
    </div>
    
  )

}

export default PopUp